@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@1,600&display=swap');

img {
    max-width: 100%;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c1c4cc !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #c1c4cc !important;
}

.transparent {
    background-color: transparent;
}

.pagado-hide {
    display: none !important;
}

.table {
    font-size: 0.95rem;
}

.col.checkout-buttons-wrapper,
.checkout-buttons-wrapper {
    padding-left: 0;
    padding-right: 0;
}

.checkout-buttons-wrapper button.btn {
    line-height: 2;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 0;
    letter-spacing: 0.5px;
}

.pagado-popup .popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: #eee;
    padding: 50px 0;
    transform: translate(-50%, -50%);
    font-size: 0.9rem;
    overflow: hidden;
    z-index: 101;
    box-shadow: 0px 0px 20px #00000054;
    border-radius: 5px;
    max-width: 100%;
}

.content-table {
    display: table;
    word-break: break-all;
    line-height: 2.6;
    font-size: 1rem;
    width: 100%;
}

.content-table.border {
    border: 1px solid #ccc5d5 !important;
}

.content-table ion-icon,
.content-table svg {
    vertical-align: text-top;
    padding-right: 12px;
    font-size: 1.2rem;
    color: #70697e;
}

.content-table img {
    padding-right: 12px;
    width: 2rem;
}

.content-table .entry div {
    display: table-cell;
    padding: 0 12px;
}

.content-table.border .entry div {
    border-top: 1px solid #ccc5d5;
}

.content-table.border div:first-child div {
    border-top: none;
}

.content-table .entry {
    display: table-row;
}

.content-table.border .entry {
    border: 1px solid #ccc5d5;
}

.content-table.border .value {
    border-left: 1px solid #ccc5d5;
}

.content-table.border .name {
    word-break: break-word;
}

.pagado-popup .popup-bg {
    background-color: #000;
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: 0.5;
}

.top-navigation {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    height: 64px;
}

.top-navigation .navbar-brand img {
    display: inline-block;
    top: -2px;
    position: relative;
    margin-right: 2px;
}

body.dark-mode .top-navigation .form-switch .form-check-input:checked~.form-check-label::after {
    background: #3F269B !important;
}

.top-navigation .form-switch .hydrated {
    display: inline-block;
    font-size: 1.6rem;
    vertical-align: unset;
    margin-right: 10px;
    color: #b8a4ff;
}

body.dark-mode .table tr {
    background-color: #2f2844;
}

body.dark-mode h2.swal2-title {
    color: #545454;
}

.table-striped>tbody>tr:nth-of-type(2n+1)>* {
    color: #6e6588;
}

.transaction-table,
.section.hero {
    word-wrap: anywhere;
    word-break: break-all;
    line-break: anywhere;
}

.card.paypal .card-header {
    display: block;
    text-align: center;
}

.grid-center {
    display: grid;
    justify-content: center;
    text-align: center;
}

.dark-mode .border-bottom {
    border-bottom: solid #27213c !important;
}

.g-recaptcha {
    background-color: transparent;
    display: flex;
    justify-content: center;
}

.home-action-buttons {
    margin-top: 5rem;
}

.top-navigation .profile-img-wrapper {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 2px solid #7e5aff;
    cursor: pointer;
    background-size: cover;
    background-color: #5235b9;
}

.top-navigation .profile-img {
    width: 100%;
}

.theme-switch.form-switch .form-check-label {
    text-align: center;
    font-size: 1.5rem;
    display: inline;
    line-height: 1.3;
    color: #d5c9ff;
}

.dark-mode .theme-switch.form-check .form-check-label {
    color: #030108;
}

.op-wrapper .form-switch .form-check-input:checked~.form-check-label::before {
    box-shadow: inset -9px -9px #fff;
    background-color: #3f269b;
}

.op-wrapper .form-switch .form-check-label::after {
    background: #BFBFF5;
}

.copy-action {
    cursor: pointer;
}

.copy-action:active {
    color: rgb(98, 236, 98);
}

.op-wrapper {
    display: flex;
}

.op-wrapper>div {
    display: grid;
    align-items: center;
}

body.home {
    background: linear-gradient(-45deg, #4225BC, #3F25B2, #4528BD, #4024B7, #432CA7, #3B20B1, #39239C, #452CB6, #412C99);
    background-size: 400% 400%;
    animation: gradient 9s ease infinite;
    height: 100vh;
}

body.home,
body.home a,
body.home p,
body.home h1 {
    color: #ffffff !important;
}

body.home .btn-outline-secondary {
    border-color: #ffffff;
}

.transaction-list {
    background-color: #ededf5;
}

.transaction-list .card {
    margin: 5px 0;
    padding: 10px 20px 15px;
}

.transaction-list .author-details,
.transaction-list .transaction-details {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.transaction-list .transaction-details {
    margin-bottom: 5px;
}

.transaction-list .author-details .title,
.transaction-list .transaction-details .date {
    font-size: 0.84rem;
    line-height: 1.2;
    font-weight: 500;
    max-width: 80%;
}

.transaction-list .amount {
    font-weight: bold;
    font-size: 1.5rem;
}

.transaction-list .amount img {
    width: 22px;
    vertical-align: bottom;
    margin-right: 5px;
}

.transaction-list .amount {
    vertical-align: bottom;
}

.transaction-list .img {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    overflow: hidden;
}

.transaction-list .img img {
    width: 100%;
}

h2.total span {
    display: block;
    font-size: 1rem;
    line-height: 1.5;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.splash-page #pagado-text {
    width: 12rem;
    filter: invert(100%);
}

@media only screen and (max-width: 992px) {

    #navbarContent.collapse.show,
    #navbarContent.collapsing {
        display: flex;
        flex-direction: column-reverse;
    }

    .op-wrapper {
        width: 100%;
        justify-content: space-between;
        margin: 1.5rem 0 0.5rem;
    }
}

.dynamic-text {
    display: flex;
    height: 10rem;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
    text-align: center;
    font-family: 'Nunito', sans-serif;
}

.dynamic-text .line {
    font-size: 2rem;
    font-weight: bold;
    color: #ffffff;
    /* letter-spacing: 2px; */
    position: absolute;
    opacity: 0;
    text-transform: uppercase;
}

.docs img {
    margin: 1rem 0;
}

@media only screen and (max-width: 768px) {
    .section.hero .total {
        font-size: 1.2rem;
    }

    .section.hero .caption {
        font-size: 0.8rem;
    }

    .section.hero .caption span {
        display: none;
    }

    .transaction-table {
        font-size: 0.8rem;
    }

    .pagado-hide-mobile {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .pagado-hide-desktop {
        display: none;
    }
}
